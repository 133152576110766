import React from 'react';
import {connect} from 'react-redux';
import strings from '../../utils/strings';

import AudioPlayer from '../../components/AudioPlayer';
import {VARIATION} from '../../constants';

class Intro extends React.Component {
	constructor(props) {
		super(props);
		const variation = VARIATION;
		const language = strings.getLanguage();
		const questions = require(`../../assets/sounds/${variation}/${language}/3 01 Tulleord Intro.mp3`);
		this.audio = questions;
	}

	render() {
		const {onNextStep} = this.props;
		return (
			<div>
				<p>
					<AudioPlayer 
					onErrorPlay={()=>{
						console.log('Something went wrong');
						}} 
					autoplay 
					inline 
					src={this.audio} />
					{strings.tulleordtestenIntro}
				</p>
				<p>{strings.tulleordtestenPractice}</p>
				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.toPractice}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
