import React from 'react';
import {connect} from 'react-redux';

import strings from '../../utils/strings';

import AudioPlayer from '../../components/AudioPlayer';

import tutorialVideo from './assets/99c06a75c2d10f2ad5d81a1aa870fc65.mp4';

import previewVideoNo from './assets/99c06a75c2d10f2ad5d81a1aa870fc65.mp4';
import previewVideoNn from './assets/99c06a75c2d10f2ad5d81a1aa870fc65.mp4';

import previewVideoSe from './assets/99c06a75c2d10f2ad5d81a1aa870fc65.mp4';
import previewVideoDe from './assets/DE_reading.mp4';

import {VARIATION} from '../../constants';

class Intro extends React.Component {
	constructor() {
		super();

		this.state = {
			removeVideo: false,
			erroPlay:false,
		};
		const variation = VARIATION;
		const language = strings.getLanguage();
		const previewVideoObj = {
			no: previewVideoNo,
			nn: previewVideoNn,
			se: previewVideoSe,
			de: previewVideoDe,
		};
		this.currentLang = language;
		this.audio = require(`../../assets/sounds/${variation}/${language}/5 1 Leseforstaelse Intro.mp3`);
		this.plainAudio = new Audio(this.audio);
		this.videoRefView = React.createRef();
		this.video = previewVideoObj[language];
	}

	render() {
		const {onNextStep} = this.props;

		let videoRef;

		return (
			<div>
				<div className="text-left">
					<p>
						<AudioPlayer
							disabled={this.state.erroPlay}
							autoplay
							onErrorPlay={()=>{
								console.log('Something went wrong');
								this.setState({
									erroPlay:true
								});
								this.plainAudio.play();
							}}
							inline
							onStart={() => {
								const scrollActionTime =
									this.currentLang === 'de' ? 38300 : null;

								if (scrollActionTime) {
									setTimeout(() => {
										if (this.videoRefView.current) {
											this.videoRefView.current.scrollIntoView(
												{
													behavior: 'smooth',
													block: 'start',
												}
											);
											videoRef.play();
										}
									}, scrollActionTime);
								}
							}}
							onPause={() => {
								videoRef.play();
							}}
							onComplete={() => {
								videoRef.play();
								setTimeout(() => {
									this.setState({
										removeVideo: true,
									});
								}, 10000);
							}}
							src={this.audio}
						/>
						{strings.leseprovenIntro}
					</p>
					<p>{strings.leseprovenTimeLimit}</p>
				</div>
				{!this.state.removeVideo && (
					<div className="lit-preview" ref={this.videoRefView}>
						<p className="lit-preview__label">
							{strings.testLooksLikeThis}
						</p>
						<video
							className="lit-preview__video"
							loop
							ref={(ref) => {
								videoRef = ref;
							}}
							src={this.video}
						/>
					</div>
				)}

				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.start}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
