import React from 'react';

import strings from '../../utils/strings';
import AudioPlayer from '../../components/AudioPlayer';

class FinishSound extends React.Component {
	constructor(props) {
		super(props);
		this.audioRefs = {};
	}
	componentDidMount() {}

	render() {
		if (!this.props.renderFinalSound || strings.getLanguage() !== 'de') {
			return <div></div>;
		}
		return (
			<div>
				<p className="instruction">
					<AudioPlayer
					onErrorPlay={()=>{
						console.log('Something went wrong');
					}}
						autoplay
						id="exampleSound"
						inline
						src={this.props.audioSrc}
						refs={this.audioRefs}
					/>
					{this.props.text}
				</p>
			</div>
		);
	}
}

export default FinishSound;
